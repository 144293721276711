import Spinner from "~/ui/Spinner";

export interface DMEProviderParams {
  id: string;
}

export const LoadingScreen = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8 text-[#07284A]">
      <Spinner />
      <h1 className="text-3xl font-medium text-[#07284A]">
        We’re processing your information
      </h1>
      <p className="text-2xl">
        This may take a moment—thank you for your patience!
      </p>
    </div>
  );
};
