import { useOutlet } from "react-router-dom";
import { twMerge as tw } from "tailwind-merge";

import { ROUTES } from "~/router";
import { CompliantRx } from "../assets";

export const Layout = () => {
  const outlet = useOutlet();
  const isHome = location.pathname === ROUTES.home;
  return (
    <div
      className={tw(
        `flex h-screen flex-col overflow-hidden bg-[#FCFCFC]`,
        isHome &&
          "bg-gradient-to-br from-[#FCFCFC] from-30% to-[#FFD100] to-80%",
        !isHome && "bg-gradient-to-r from-[#FFFFC199] to-[#FFD100]",
      )}
    >
      <header className={tw("flex items-center justify-between px-16 py-8")}>
        <a className="text-sm font-medium text-sky-950" href="/">
          <span className="font-bold">MyFreeStyle</span> Navigator
        </a>
        <img src={CompliantRx} alt="Compliant Logo" />
      </header>
      <main
        className={tw(
          "grid h-full flex-grow overflow-auto px-16 py-8 pb-16",
          !isHome && "rounded-tr-[5rem] bg-white",
        )}
      >
        {outlet}
      </main>
    </div>
  );
};
