interface ProviderType {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
}

export const ProviderCard = ({ name, email, phoneNumber }: ProviderType) => {
  return (
    <div className="group w-full overflow-hidden rounded-lg border border-[#F0F7FF] text-center shadow transition-all duration-300 hover:border-[#36AAFA] hover:bg-[#E0EFFE]">
      <div className="bg-[#F0F7FF] p-4 font-bold text-[#07284A] transition-all duration-300 group-hover:bg-[#B9DFFE]">
        <h1>{name}</h1>
      </div>
      <div className="flex flex-col gap-2 p-4 text-[#6B7280] transition-all duration-300 group-hover:text-[#07284A]">
        <p>{email}</p>
        <p>{phoneNumber}</p>
      </div>
    </div>
  );
};
