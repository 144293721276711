import { Link } from "react-router-dom";

import { Button } from "~/components/Button";

export const Transition = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex max-w-[1200px] flex-col items-center justify-center gap-6">
        <div className="flex flex-col items-center justify-center gap-6">
          <h1 className="text-3xl">
            It looks like you may not qualify for a CGM through your insurance.
          </h1>
          <p className="text-[#07284A]">
            But don’t worry—we have another option for you! Abbott’s Lingo
            System is a great alternative for managing your glucose levels and
            is available without a prescription. Click below to explore how
            Lingo can help you take control of your health today.
          </p>
        </div>
        <Link to="https://www.hellolingo.com/">
          <Button className="min-w-48" variant="primary">
            Learn More About Lingo
          </Button>
        </Link>
      </div>
    </div>
  );
};
