import { create } from "zustand";

import { StatesTypes } from "~/utils/usaStates";

export interface PersonalFormData {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  state: StatesTypes;
  zipCode?: string;
  insuranceType?: string;
}

export interface InsuranceData {
  bin: string;
  pcn?: string;
  group?: string;
}
export interface MultiStepFormData {
  personalFormData?: PersonalFormData;
  addressFormData?: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  insuranceFormData?: {
    insuranceType: string;
    insuranceProvider?: string;
  };
  medicalInformationFormData?: {
    diabetesType: string;
    useInsulin: string;
  };
  insuranceData?: InsuranceData;
}

export interface MultiStepFormState {
  currentFormStep: number;
  multiStepFormData: MultiStepFormData | undefined;
  goToNextFormStep(): void;
  goToPreviousFormStep(): void;
  setMultiStepFormData(multiStepFormData: MultiStepFormData): void;
}

export const useMultiStepFormStore = create<MultiStepFormState>()((set) => ({
  currentFormStep: 1,
  multiStepFormData: undefined,
  goToNextFormStep: () => {
    set(({ currentFormStep }) => ({
      currentFormStep: currentFormStep + 1,
    }));
  },
  goToPreviousFormStep: () => {
    set(({ currentFormStep }) => ({
      currentFormStep: currentFormStep - 1,
    }));
  },
  setMultiStepFormData: (update: Partial<MultiStepFormData>) => {
    set((prevState) => ({
      multiStepFormData: {
        ...prevState.multiStepFormData,
        ...update,
      },
    }));
  },
}));
