import { InsuranceData, PersonalFormData } from "~/stores";
import { ServiceResponse } from "./api.types";
import { api } from "./axios";

export interface CoverageResponse {
  path: string;
}

export interface BenefitResponse {
  benefit: string;
}

export interface BPGAllDataResponse {
  query: {
    bin: string;
    pcn: string;
    group: string;
    clientId: string;
  };
  results: [
    {
      BIN: string;
      PCN: string;
      GROUP_ID: string;
      PAYER_ENTITY: string;
      BOB: string;
      ENTERPRISE: string;
      POP_DESC: string;
      PAYER_PLAN_DETAIL: string;
      PBM_PROCESSOR_VENDOR: string;
      BULK_SEARCH_ID: string;
      QUERY_ID: string;
    },
  ];
}

export interface PrecisionWithAllDataResponse {
  programId: string;
  precisionPatientId: string;
  programData: {
    patientZip3CD: string;
    rxPayerEntityId: string;
    rxPayerEntity: string;
    rxBobId: string;
    rxBob: string;
    rxEnterpriseId: string;
    rxEnterprise: string;
    rxPopDesc: string;
    mxBobId: string;
    mxBob: string;
    mxEnterpriseId: string;
    mxEnterprise: string;
    mxPopDesc: string;
    patientPopDesc: string;
  };
}

export interface PVerifyAllDataResponse {
  payer: string;
  planCoverageSummary: {
    status: string;
    effectiveDate?: string;
    expiryDate?: string;
    planName: string;
    policyType: string;
    groupNumber: string;
  };
}

export const getPrecisionAQCoverage = async (
  personalFormData: PersonalFormData,
) => {
  const { data } = await api.post<ServiceResponse<BenefitResponse>>(
    "/api-2",
    personalFormData,
  );
  return data.data;
};

export const getPrecisionWithAllDataAQCoverage = async (
  personalFormData: PersonalFormData,
) => {
  const { data } = await api.post<
    ServiceResponse<PrecisionWithAllDataResponse>
  >("/api-2-all-data", personalFormData);
  return data.data;
};

export const getPVerifyWithAllDataCoverage = async (
  personalFormData: PersonalFormData,
) => {
  const { data } = await api.post<ServiceResponse<PVerifyAllDataResponse>>(
    "/api-1",
    personalFormData,
  );
  return data.data;
};

export const getBPGCoverage = async (insuranceData: InsuranceData) => {
  const { data } = await api.post<ServiceResponse<BenefitResponse>>(
    "/api-3/coverage",
    insuranceData,
  );
  return data.data;
};

export const getBPGEligibility = async (personalFormData: PersonalFormData) => {
  const { data } = await api.post<ServiceResponse<BenefitResponse>>(
    "/api-3/eligibility",
    personalFormData,
  );
  return data.data;
};

export const getBPGAllData = async (insuranceData: InsuranceData) => {
  const { data } = await api.post<ServiceResponse<BPGAllDataResponse>>(
    "/api-3/coverage/all-data",
    insuranceData,
  );
  return data.data;
};
