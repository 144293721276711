import React, { ComponentPropsWithoutRef, MouseEvent, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface DatePickerProps extends ComponentPropsWithoutRef<"input"> {
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  children?: ReactNode;
  defaultValue?: string;
  inputClassName?: string;
  isError?: boolean;
  isOptional?: boolean;
  tooltip?: string;
}

const DatePicker = React.forwardRef<
  HTMLInputElement,
  Omit<DatePickerProps, "type">
>(
  (
    {
      id,
      label,
      error,
      placeholder,
      className,
      defaultValue,
      children,
      inputClassName,
      isError = false,
      isOptional = false,
      tooltip,
      ...props
    }: DatePickerProps,
    ref,
  ) => {
    const handleOpenDatePicker = (e: MouseEvent<HTMLInputElement>) => {
      const inputElement = e.currentTarget;
      inputElement.showPicker();
    };
    return (
      <div className="flex w-full flex-col gap-2">
        <div className="flex items-center gap-2">
          <div className="flex w-fit gap-1">
            {label && (
              <label
                htmlFor={id}
                className="px-0 py-1.5 font-semibold text-[#07284A]"
              >
                {label}
              </label>
            )}
          </div>
        </div>
        <div
          className={twMerge(
            "bg-neutral-25 flex w-full items-center justify-between rounded-md text-sm",
            className,
            (isError || error) && "border-error bg-red-50",
          )}
        >
          <input
            className={twMerge(
              "disabled:text-gray-60 w-full rounded-lg border border-[#B9DFFE] bg-transparent p-4 placeholder:text-left focus:outline-none focus:ring-0 disabled:bg-gray-200",
              (isError || error) && "text-error",
              inputClassName,
            )}
            id={id}
            type="date"
            onClick={handleOpenDatePicker}
            placeholder={placeholder ?? `Enter ${label?.toLocaleLowerCase()}`}
            {...props}
            ref={ref}
            defaultValue={defaultValue}
          />
          {children}
        </div>
      </div>
    );
  },
);

DatePicker.displayName = "DatePicker";
export default DatePicker;
