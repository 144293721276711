import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { twMerge as tw } from "tailwind-merge";
import { z } from "zod";

import { useMultiStepFormStore } from "~/stores";
import { Input } from "../../components/Input";

import "react-international-phone/style.css";

import { Button } from "~/components/Button";
import ErrorMessage from "~/components/ErrorMessage";
import { FormLayout } from "~/components/forms";
import { useGetBPGCoverage } from "../../hooks/useGetBPGCoverage";
import { LoadingScreen } from "../LoadingScreen";

const formSchema = z.object({
  bin: z.string().min(1, { message: "BIN is required" }),
  pcn: z.string().optional(),
  group: z.string().optional(),
});

export type FormInputType = z.infer<typeof formSchema>;

export const InsuranceCardForm = () => {
  const { setMultiStepFormData, multiStepFormData, goToPreviousFormStep } =
    useMultiStepFormStore();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...multiStepFormData?.insuranceData,
    },
    mode: "onSubmit",
  });

  const { mutate: getBPGMutation, isPending, isError } = useGetBPGCoverage();

  const onSubmit: SubmitHandler<FormInputType> = (data) => {
    if (Object.keys(errors).length === 0) {
      setMultiStepFormData({
        insuranceData: {
          ...data,
        },
      });
      if (multiStepFormData) {
        getBPGMutation(data);
      }
    }
  };

  if (isPending) return <LoadingScreen />;

  return (
    <FormLayout>
      <div className="flex h-full flex-col gap-4">
        <div className="flex justify-between">
          <p className="font-medium">
            Fill in these details from your insurance card so we can check your
            eligibility.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-col justify-between"
        >
          <div className="flex flex-col gap-9">
            <div className="flex justify-between gap-4">
              <Input
                id="bin"
                label="BIN"
                {...register("bin")}
                errorMessage={errors.bin?.message}
              />

              <Input
                id="pcn"
                label="PCN"
                {...register("pcn")}
                errorMessage={errors.pcn?.message}
              />

              <Input
                id="group"
                label="Group"
                {...register("group")}
                errorMessage={errors.group?.message}
              />
            </div>
            <p className="text-center text-gray-600">
              You can find these details at the bottom of your insurance card
            </p>
          </div>
          <div className="flex flex-col gap-6">
            {isError && <ErrorMessage />}
            <div className="flex justify-between">
              <Button
                className={tw("w-1/4")}
                variant="secondary"
                onClick={() => goToPreviousFormStep()}
              >
                Back
              </Button>
              <Button
                variant="primary"
                disabled={!isValid}
                isLoading={isPending}
                className={tw("w-1/4")}
                type="submit"
                onClick={() => {
                  console.log({ multiStepFormData });
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </FormLayout>
  );
};
