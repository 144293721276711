import type { ComponentType } from "react";

import { useMultiStepFormStore } from "~/stores";
import { InsuranceCardForm } from "./InsuranceCardForm";
import { InsuranceForm } from "./insuranceForm";
import { MedicalInformationForm } from "./MedicalInformationForm";
import { PersonalForm } from "./personalForm";

const stepComponents: Record<number, ComponentType> = {
  1: MedicalInformationForm,
  2: InsuranceForm,
  3: PersonalForm,
  4: InsuranceCardForm,
};

const MultiStepForm = () => {
  const { currentFormStep } = useMultiStepFormStore();

  const StepComponent = stepComponents[currentFormStep];

  return <StepComponent />;
};

export default MultiStepForm;
