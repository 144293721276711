import { Link } from "react-router-dom";

import { Button } from "~/components/Button";
import { ROUTES } from "~/router";
import { Devices } from "../assets";

export const Home = () => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex w-[50%] flex-col gap-12 self-center">
        <div className="flex flex-col gap-8">
          <h3 className="text-freestyle-libre-red text-2xl">
            MyFreeStyle Navigator
          </h3>
          <h1 className="text-blue text-5xl">
            Track Your Glucose With{" "}
            <span className="font-medium">Confidence</span>
          </h1>

          <p className="text-[#051E38]">
            The world&lsquo;s smallest sensor streams readings directly to your
            smartphone* so you can know your glucose levels with just a quick
            glance.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <Link to={ROUTES.precisionForm}>
            <Button
              variant="primary"
              className="w-2/3 text-center hover:font-bold"
            >
              Start Flow 1
            </Button>
          </Link>
          <Link to={ROUTES.bpgForm}>
            <Button
              variant="primary"
              className="w-2/3 text-center hover:font-bold"
            >
              Start Flow 2
            </Button>
          </Link>
        </div>
      </div>
      <div className="">
        <img src={Devices} alt="ProfilePicture" />
      </div>
    </div>
  );
};
