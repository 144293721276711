import type { IconProps } from "~/utils/types";

export const InfoIcon = ({ className }: IconProps) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.74998 14.6654C11.4319 14.6654 14.4166 11.6806 14.4166 7.9987C14.4166 4.3168 11.4319 1.33203 7.74998 1.33203C4.06808 1.33203 1.08331 4.3168 1.08331 7.9987C1.08331 11.6806 4.06808 14.6654 7.74998 14.6654Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75 10.6667V8"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75 5.33203H7.75667"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
