import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { twMerge as tw } from "tailwind-merge";
import { z } from "zod";

import { Button } from "~/components/Button";
import { FormLayout } from "~/components/forms";
import RadioButton from "~/components/RadioButton";
import { ROUTES } from "~/router";
import { useMultiStepFormStore } from "~/stores";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../components/Select";

const DIABETES_TYPES = [
  { id: 2, value: "type-1", label: "Type 1" },
  { id: 3, value: "type-2", label: "Type 2" },
  { id: 4, value: "type-3", label: "Gestational" },
];

const MedicalInformationFormSchema = z.object({
  diabetesType: z.string().min(1, { message: "Diabetes type is required" }),
  useInsulin: z.string().min(1, { message: "Required" }),
});

export type MedicalInformationFormInputType = z.infer<
  typeof MedicalInformationFormSchema
>;

export const MedicalInformationForm = () => {
  const { setMultiStepFormData, multiStepFormData, goToNextFormStep } =
    useMultiStepFormStore();

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    formState: { isValid },
  } = useForm<MedicalInformationFormInputType>({
    resolver: zodResolver(MedicalInformationFormSchema),
    defaultValues: {
      ...multiStepFormData?.medicalInformationFormData,
    },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<MedicalInformationFormInputType> = (data) => {
    if (data.useInsulin == "no") {
      navigate(ROUTES.transition);
    } else {
      setMultiStepFormData({ medicalInformationFormData: data });
      goToNextFormStep();
    }
  };

  return (
    <FormLayout>
      <div className="flex h-full flex-col gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-col justify-between gap-8"
        >
          <div className="flex flex-col gap-8">
            <div className="flex justify-between gap-4">
              <Controller
                control={control}
                name="diabetesType"
                render={({ field }) => (
                  <SelectGroup className="w-full">
                    <SelectLabel className="mb-1 font-semibold">
                      What is your diabetes type?
                    </SelectLabel>
                    <Select {...field} onValueChange={field.onChange}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select diabetes type" />
                      </SelectTrigger>
                      <SelectContent>
                        {DIABETES_TYPES.map(({ id, label, value }) => {
                          return (
                            <SelectItem key={`${id}${value}`} value={value}>
                              {label}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </SelectGroup>
                )}
              />
              <div className="flex w-full flex-col gap-4">
                <p className="px-0 py-2 font-semibold">
                  Do you treat your diabetes with any type of insulin?
                </p>
                <div className="flex flex-row gap-4">
                  <RadioButton
                    label={"Yes"}
                    value={"yes"}
                    {...register("useInsulin")}
                  />
                  <RadioButton
                    label={"No"}
                    value={"no"}
                    {...register("useInsulin")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              variant="primary"
              disabled={!isValid}
              className={tw("w-1/4")}
              type="submit"
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </FormLayout>
  );
};
