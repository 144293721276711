import { Button } from "~/components/Button";

const OptionList = ({ options }: { options: string[] }) => {
  return (
    <ul className="flex flex-row gap-4">
      {options.map((option) => (
        <li key={option} className="flex items-center gap-2">
          <span className="text-xl">•</span>
          <span>{option}</span>
        </li>
      ))}
    </ul>
  );
};

export const Pharmacy = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-6 text-[#07284A]">
      <div className="flex flex-col items-center justify-center gap-3">
        <h1 className="text-4xl">Great news!</h1>
        <div className="flex flex-col gap-5 text-center">
          <h1 className="text-2xl text-[#07284A]">
            Your <b className="text-[#0C8FEB]">Freestyle Libre CGM System</b> is
            covered as a pharmacy benefit.
          </h1>
        </div>
        <p className="text-[#07284A]">
          We’re here to help you get started! Call us at{" "}
          <span className="text-[#0C8FEB]">1-888-408-0940</span>, and we can:
        </p>
      </div>
      <div className="flex flex-row gap-3 pb-4">
        <OptionList
          options={[
            "Coordinate with your doctor to get the prescription.",
            "Arrange to have your CGM delivered to your home.",
          ]}
        />
      </div>
      <Button className="min-w-48" variant="primary">
        Call for Help
      </Button>
    </div>
  );
};
