import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Layout } from "~/layout";
import { DMEProvider, Home, ProvidersList } from "~/screens";
import { BPGApiInformation } from "~/screens/BPGApiInformation";
import { CouponBenefit } from "~/screens/CouponBenefit";
import MultiStepForm from "~/screens/MultiStepForm";
import { Pharmacy } from "~/screens/pharmacy/Pharmacy";
import { PharmacyBenefit } from "~/screens/PharmacyBenefit";
import PrecisionLookup from "~/screens/PrecisionLookup";
import PVerifyLookup from "~/screens/PVerifyLookup";
import { Transition } from "~/screens/transition/Transition";
import { ROUTES } from "./routes";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<Home />} path={ROUTES.home} />
          <Route element={<MultiStepForm />} path={ROUTES.precisionForm} />
          <Route element={<PrecisionLookup />} path={ROUTES.precisionLookup} />
          <Route element={<PVerifyLookup />} path={ROUTES.pVerifyLookup} />
          <Route element={<MultiStepForm />} path={ROUTES.bpgForm} />
          <Route element={<PharmacyBenefit />} path={ROUTES.pharmacyBenefit} />
          <Route element={<CouponBenefit />} path={ROUTES.couponBenefit} />
          <Route element={<ProvidersList />} path={ROUTES.providersList} />
          <Route element={<DMEProvider />} path={ROUTES.provider} />
          <Route element={<Pharmacy />} path={ROUTES.pharmacy} />
          <Route element={<Transition />} path={ROUTES.transition} />
          <Route element={<BPGApiInformation />} path={ROUTES.bpgLookup} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
