import { generatePath, Link } from "react-router-dom";

import { ROUTES } from "~/router";
import { DMEProviders } from "./mockData";
import { ProviderCard } from "./ProviderCard";

interface DMEProviderType {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
}

export const ProvidersList = () => {
  const shuffleArray = (array: DMEProviderType[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <div className="mx-auto flex w-11/12 flex-col gap-12 pb-16">
      <div className="flex flex-col gap-4 text-center">
        <h1 className="pt-8 text-3xl text-[#07284A]">
          Your <b className="text-[#0C8FEB]">Freestyle Libre CGM System</b> is
          covered as a Medical Benefit.
        </h1>
        <p className="text-[#07284A]">
          Select a Durable Medical Equipment (DME) provider from the list below.
          Once you make your selection, we’ll send your information directly to
          the provider so they can assist you in getting your device.
        </p>
        <p className="text-[#07284A]">
          If you’re not sure which provider to choose or need help, call us at{" "}
          <span className="text-[#0C8FEB]">1-888-408-0940</span>.
        </p>
      </div>
      <div className="grid grid-cols-3 justify-center gap-4">
        {shuffleArray(DMEProviders).map((provider) => {
          return (
            <Link
              to={generatePath(ROUTES.provider, {
                id: `${provider.id}`,
              })}
            >
              <ProviderCard key={provider.id} {...provider} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
