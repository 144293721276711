import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getBPGAllData, getBPGCoverage } from "~/api/precisionAndBPG";

export const useGetBPGCoverage = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: getBPGCoverage,
    onSuccess: (response) => {
      if (response.benefit === "medicalBenefit") {
        return navigate("/providersList");
      }
      if (response.benefit === "pharmacyBenefit") {
        navigate("/pharmacy");
      }
    },
  });
};

export const useGetBPGAllData = () => {
  return useMutation({
    mutationFn: getBPGAllData,
  });
};
