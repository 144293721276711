import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { twMerge as tw } from "tailwind-merge";
import { z } from "zod";

import { FormLayout } from "~/components/forms";
import { useMultiStepFormStore } from "~/stores";
import { Button } from "../../components/Button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/Select";

const PLAN_TYPE = [
  {
    id: 1,
    value: "medicare",
    label: "Medicare (I have a red, white and blue card only)",
  },
  {
    id: 2,
    value: "medicare-advantage",
    label: "Medicare Advantage (Medicare with and Advantage plan)",
  },
  { id: 3, value: "medicaid", label: "Medicaid" },
  {
    id: 4,
    value: "commercial",
    label: "Private insurance (ie UHC, BCBS, Humana, etc.)",
  },
];

const baseSchema = z.object({
  insuranceType: z.string().min(1, { message: "Insurance plan is required" }),
});

export type InsuranceFormInputType = z.infer<typeof baseSchema>;

export const InsuranceForm = () => {
  const {
    goToPreviousFormStep,
    setMultiStepFormData,
    multiStepFormData,
    goToNextFormStep,
  } = useMultiStepFormStore();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<InsuranceFormInputType>({
    resolver: (values, context, options) => {
      const createResolver = zodResolver(baseSchema);
      return createResolver(values, context, options);
    },
    defaultValues: {
      insuranceType: multiStepFormData?.insuranceFormData?.insuranceType,
    },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<InsuranceFormInputType> = (data) => {
    setMultiStepFormData({ insuranceFormData: data });
    goToNextFormStep();
  };

  return (
    <FormLayout>
      <div className="flex h-full flex-col gap-4">
        <div className="flex justify-between">
          <p className="text-lg font-medium text-[#051E38]">
            Fill in these details so we can check your eligibility.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={tw("flex h-full flex-col gap-4")}
        >
          <div className="flex h-full justify-between gap-4">
            <Controller
              control={control}
              name="insuranceType"
              render={({ field }) => (
                <SelectGroup className="w-full">
                  <SelectLabel className="font-semibold">
                    Insurance type
                  </SelectLabel>
                  <Select {...field} onValueChange={field.onChange}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select your insurance type" />
                    </SelectTrigger>
                    <SelectContent>
                      {PLAN_TYPE.map(({ id, label, value }) => {
                        return (
                          <SelectItem
                            {...field}
                            key={`${id}${value}`}
                            value={value}
                          >
                            {label}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </SelectGroup>
              )}
            />
          </div>
          <div className="flex justify-between">
            <Button
              variant="secondary"
              className={tw("w-1/4")}
              onClick={() => goToPreviousFormStep()}
            >
              Back
            </Button>
            <Button
              variant="primary"
              disabled={!isValid}
              className={tw("w-1/4")}
              type="submit"
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </FormLayout>
  );
};
