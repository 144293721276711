import { useParams } from "react-router-dom";

import { Button } from "~/components/Button";
import { DMEProviders } from "./mockData";

export interface DMEProviderParams {
  id: string;
}

export const DMEProvider = () => {
  const { id } = useParams<keyof DMEProviderParams>() as DMEProviderParams;

  const provider = DMEProviders.find((provider) => provider.id === Number(id));

  if (!provider) return <h1>Provider not found</h1>;

  const { name } = provider;

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8 text-[#07284A]">
      <div className="flex flex-col items-center justify-center gap-3">
        <h1 className="text-3xl font-medium">
          Thank you for selecting {name}!
        </h1>
        <p className="text-[#07284A]">
          We’ve sent your information to the provider, and they’ll contact you
          shortly to assist with your Freestyle Libre CGM System.
        </p>
        <p className="text-[#07284A]">
          If you’d like immediate assistance or have questions, call us at{" "}
          <span className="text-[#0072CD]">1-888-408-0940</span>
        </p>
      </div>
      <Button
        onClick={() => window.open("tel:1-888-408-0940")}
        className="min-w-48"
        variant="primary"
      >
        Call Us Now
      </Button>
    </div>
  );
};
