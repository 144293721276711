import { useState } from "react";

import { getPrecisionWithAllDataAQCoverage } from "~/api/precisionAndBPG";
import type { PrecisionWithAllDataResponse } from "~/api/precisionAndBPG";
import { PersonalFormData } from "~/stores/useMultiStepFormStore";
import { PersonalForm } from "./personalForm";

export default function PrecisionLookup() {
  const [data, setData] = useState<PrecisionWithAllDataResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (formData: PersonalFormData) => {
    try {
      setLoading(true);
      setError(null);
      const response = await getPrecisionWithAllDataAQCoverage(formData);

      if (!response || (Array.isArray(response) && response.length === 0)) {
        setData(null);
        setError("No coverage information available.");
      } else {
        setData(response);
      }
    } catch (error) {
      setError("An error occurred while fetching coverage information.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <PersonalForm isPrecisionLookup={true} onSubmit={handleSubmit} />

      {loading && <p className="text-gray-500">Loading...</p>}

      {error && !loading && <p className="text-red-500">{error}</p>}

      {data && !loading && !error && (
        <div className="mt-8 rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-6 text-2xl font-bold text-[#07284A]">
            Coverage Information
          </h2>

          <div className="grid grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-[#07284A]">
                  Patient Information
                </h3>
                <p>
                  <span className="font-medium">Program ID:</span>{" "}
                  {data.programId}
                </p>
                <p>
                  <span className="font-medium">Patient ID:</span>{" "}
                  {data.precisionPatientId}
                </p>
                <p>
                  <span className="font-medium">ZIP Code:</span>{" "}
                  {data.programData.patientZip3CD}
                </p>
                <p>
                  <span className="font-medium">Population:</span>{" "}
                  {data.programData.patientPopDesc}
                </p>
              </div>

              <div>
                <h3 className="font-semibold text-[#07284A]">Rx Information</h3>
                <p>
                  <span className="font-medium">Payer Entity:</span>{" "}
                  {data.programData.rxPayerEntity}
                </p>
                <p>
                  <span className="font-medium">Enterprise:</span>{" "}
                  {data.programData.rxEnterprise}
                </p>
                <p>
                  <span className="font-medium">BOB:</span>{" "}
                  {data.programData.rxBob}
                </p>
                <p>
                  <span className="font-medium">Population:</span>{" "}
                  {data.programData.rxPopDesc}
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-[#07284A]">
                  Medical Information
                </h3>
                <p>
                  <span className="font-medium">Enterprise:</span>{" "}
                  {data.programData.mxEnterprise}
                </p>
                <p>
                  <span className="font-medium">BOB:</span>{" "}
                  {data.programData.mxBob}
                </p>
                <p>
                  <span className="font-medium">Population:</span>{" "}
                  {data.programData.mxPopDesc}
                </p>
              </div>

              <div>
                <h3 className="font-semibold text-[#07284A]">IDs</h3>
                <p>
                  <span className="font-medium">Rx Payer Entity ID:</span>{" "}
                  {data.programData.rxPayerEntityId}
                </p>
                <p>
                  <span className="font-medium">Rx Enterprise ID:</span>{" "}
                  {data.programData.rxEnterpriseId}
                </p>
                <p>
                  <span className="font-medium">Rx BOB ID:</span>{" "}
                  {data.programData.rxBobId}
                </p>
                <p>
                  <span className="font-medium">Mx Enterprise ID:</span>{" "}
                  {data.programData.mxEnterpriseId}
                </p>
                <p>
                  <span className="font-medium">Mx BOB ID:</span>{" "}
                  {data.programData.mxBobId}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
