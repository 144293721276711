import { forwardRef } from "react";
import type { ComponentPropsWithRef } from "react";
import { twMerge as tw } from "tailwind-merge";

interface InputPropTypes extends ComponentPropsWithRef<"input"> {
  label: string;
  errorMessage: string | undefined;
}

export const Input = forwardRef<HTMLInputElement, InputPropTypes>(
  ({ label, id, placeholder = "", className, errorMessage, ...props }, ref) => {
    return (
      <div className={tw("gap flex w-full flex-col justify-end", className)}>
        <label
          htmlFor={id}
          className="px-0 py-1.5 font-semibold text-[#07284A]"
        >
          {label}
        </label>
        <input
          placeholder={placeholder}
          className={tw(
            "no-spinners rounded-lg border border-[#B9DFFE] bg-white p-4",
            errorMessage && "border-red-600",
          )}
          id={id}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

Input.displayName = "Input";
