export const DMEProviders = [
  {
    id: 1,
    name: "Quest Health Solutions",
    email: "info@questhealthsolutions.com",
    phoneNumber: "877-270-7050",
  },
  {
    id: 2,
    name: "United States Medical Supplies",
    email: "abbott@usmed.com",
    phoneNumber: "877-270-6508",
  },
  {
    id: 3,
    name: "Better Living Now",
    email: "libre@betterlivingnow.com",
    phoneNumber: "800-854-5729",
  },
  {
    id: 4,
    name: "Advanced Diabetes Supply",
    email: "libreintakes@northcoastmed.com",
    phoneNumber: "866-976-9110",
  },
  {
    id: 5,
    name: "Edgepark Medical Supplies",
    email: "diabetesreferrals@cardinalhealth.com",
    phoneNumber: "844-619-4650",
  },
  {
    id: 6,
    name: "CCS Medical",
    email: "libre@ccsmed.com",
    phoneNumber: "800-951-1725",
  },
  {
    id: 7,
    name: "Byram Healthcare/Apria",
    email: "abbottreferrals@byramhealthcare.com",
    phoneNumber: "800-974-2055",
  },
  {
    id: 8,
    name: "J&B Medical Supply",
    email: "providerservices@jandbmedical.com",
    phoneNumber: "800-737-0045",
  },
  {
    id: 9,
    name: "Total Medical Supply",
    email: "cgm@tmscares.com",
    phoneNumber: "877-670-1120",
  },
  {
    id: 10,
    name: "US HealthLink",
    email: "customerservice@ushealthlink.com",
    phoneNumber: "855-421-2732",
  },
  {
    id: 11,
    name: "Bridgewater Health Supplies",
    email: "info@bridgewaterhealthsupplies.com",
    phoneNumber: "800-974-2055",
  },
  {
    id: 12,
    name: "Mini Pharmacy",
    email: "info@minipharmacy.com",
    phoneNumber: "888-670-1120",
  },
  {
    id: 13,
    name: "Edwards Health Care Services",
    email: "abbott@myehcs.com",
    phoneNumber: "800-951-1725",
  },
];
