export const ROUTES = {
  home: "/",
  multiStepForm: "/stepForm",
  precisionForm: "/flow-1",
  bpgForm: "/flow-2",
  pVerifyLookup: "/integration1-raw",
  precisionLookup: "/integration2-raw",
  bpgLookup: "/integration3-raw",
  multipleChoiceForm: "/multiChoiceForm",
  pharmacyBenefit: "/pharmacyBenefit",
  providersList: "/providersList",
  provider: "/providers/:id",
  couponBenefit: "/couponBenefit",
  login: "/login",
  pharmacy: "/pharmacy",
  transition: "/transition",
} as const;

export const MODAL_ROUTES = {
  successModal: "/successModal",
} as const;
