import React, { ComponentPropsWithoutRef, ForwardedRef } from "react";

interface RadioButtonProps extends ComponentPropsWithoutRef<"input"> {
  label: string;
}

const RadioButton = React.forwardRef(
  (
    { label, id, className, children, ...rest }: RadioButtonProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <label
        htmlFor={id}
        className={"flex cursor-pointer items-center gap-1 px-1 text-[#07284A]"}
      >
        <input
          ref={ref}
          id={id}
          value={id}
          type="radio"
          className={
            "text-primary-500 border-2 border-[#D7D7D7] focus:ring-0 focus:ring-transparent"
          }
          {...rest}
        />
        <span>{label}</span>
      </label>
    );
  },
);
RadioButton.displayName = "RadioButton";
export default RadioButton;
