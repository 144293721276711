import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getPrecisionAQCoverage } from "~/api/precisionAndBPG";

export const useGetPrecisionAQCoverage = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: getPrecisionAQCoverage,
    onSuccess: (response) => {
      if (response.benefit === "medicalBenefit") {
        navigate("/providersList");
      } else {
        navigate("/pharmacy");
      }
    },
  });
};
