import { useState } from "react";

import { getPVerifyWithAllDataCoverage } from "~/api/precisionAndBPG";
import type { PVerifyAllDataResponse } from "~/api/precisionAndBPG";
import { PersonalFormData } from "~/stores/useMultiStepFormStore";
import { PersonalForm } from "./personalForm";

export default function PVerifyLookup() {
  const [data, setData] = useState<PVerifyAllDataResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (formData: PersonalFormData) => {
    try {
      setLoading(true);
      setError(null);
      const response = await getPVerifyWithAllDataCoverage(formData);

      if (!response || (Array.isArray(response) && response.length === 0)) {
        setData(null);
        setError("No coverage information available.");
      } else {
        setData(response);
      }
    } catch (error) {
      setError("An error occurred while fetching coverage information.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <PersonalForm isPVerifyLookup={true} onSubmit={handleSubmit} />

      {loading && <p className="text-gray-500">Loading...</p>}

      {error && !loading && <p className="text-red-500">{error}</p>}

      {data && !loading && !error && (
        <div className="mt-8 rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-6 text-2xl font-bold text-[#07284A]">
            Coverage Information
          </h2>

          <div className="space-y-4">
            <div>
              <h3 className="mb-2 font-semibold text-[#07284A]">
                Payer Details
              </h3>
              <p>
                <span className="font-medium">Payer:</span> {data.payer}
              </p>
            </div>

            <div>
              <h3 className="mb-2 font-semibold text-[#07284A]">
                Plan Coverage Summary
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p>
                    <span className="font-medium">Status:</span>{" "}
                    {data.planCoverageSummary.status}
                  </p>
                  <p>
                    <span className="font-medium">Plan Name:</span>{" "}
                    {data.planCoverageSummary.planName}
                  </p>
                  <p>
                    <span className="font-medium">Policy Type:</span>{" "}
                    {data.planCoverageSummary.policyType}
                  </p>
                </div>
                <div>
                  <p>
                    <span className="font-medium">Effective Date:</span>{" "}
                    {data.planCoverageSummary.effectiveDate ?? "N/A"}
                  </p>
                  <p>
                    <span className="font-medium">Expiry Date:</span>{" "}
                    {data.planCoverageSummary.expiryDate ?? "N/A"}
                  </p>
                  <p>
                    <span className="font-medium">Group Number:</span>{" "}
                    {data.planCoverageSummary.groupNumber}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
