import React from "react";
import { twMerge } from "tailwind-merge";

import { InfoIcon } from "./icons/InfoIcon";

const ErrorMessage = ({ className }: { className?: string }) => {
  return (
    <div
      className={twMerge(
        "flex w-full flex-row items-center gap-2 rounded-2xl border border-[#FFDCA9] bg-[#FFF8ED] px-4 py-3 text-sm",
        className,
      )}
    >
      <InfoIcon className="text-[#854D0E]" />
      <p className="text-[#854D0E]">Something went wrong</p>
    </div>
  );
};

export default ErrorMessage;
