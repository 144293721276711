import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getBPGEligibility } from "~/api/precisionAndBPG";
import { useMultiStepFormStore } from "~/stores";

export const useGetBPGEligibility = () => {
  const navigate = useNavigate();
  const { goToNextFormStep } = useMultiStepFormStore();
  return useMutation({
    mutationFn: getBPGEligibility,
    onSuccess: (response) => {
      if (response.benefit === "medicalBenefit") {
        return navigate("/providersList");
      }
      if (response.benefit === "pharmacyBenefit") {
        navigate("/pharmacy");
      } else {
        goToNextFormStep();
      }
    },
  });
};
