import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { twMerge as tw } from "tailwind-merge";
import { z } from "zod";

import { PersonalFormData, useMultiStepFormStore } from "~/stores";
import { Input } from "../../components/Input";

import "react-international-phone/style.css";

import { useState } from "react";
import { isBefore } from "date-fns";
import { useNavigate } from "react-router-dom";

import { delay } from "~/api";
import { Button } from "~/components/Button";
import DatePicker from "~/components/DatePicker";
import ErrorMessage from "~/components/ErrorMessage";
import { FormLayout } from "~/components/forms";
import RadioButton from "~/components/RadioButton";
import { ROUTES } from "~/router";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/Select";
import { useGetBPGEligibility } from "../../hooks/useGetBPGEligibility";
import { useGetPrecisionAQCoverage } from "../../hooks/useGetPrecisionAQCoverage";
import { usaStates, usaStatesOptions } from "../../utils/usaStates";
import { LoadingScreen } from "../LoadingScreen";

const getPersonalFormSchema = (
  isPrecision: boolean,
  isPrecisionLookup: boolean,
) =>
  z
    .object({
      firstName: z.string().min(1, { message: "First name is required" }),
      lastName: z.string().min(1, { message: "Last name is required" }),
      birthDate: z
        .string({ message: "Please provide your date of birth" })
        .refine((dateString) => isBefore(new Date(dateString), new Date()), {
          message: "Please enter a valid date of birth",
        }),
      zipCode: z.string().optional(),
      gender: z.string().min(1, { message: "Gender is required" }),
      state: z.enum(usaStates, {
        errorMap: () => ({ message: "State is required" }),
      }),
    })
    .refine((data) => !(isPrecision && !data.zipCode) || isPrecisionLookup, {
      path: ["zipCode"],
      message: "ZipCode is required",
    });

const DELAY_TIME = 5000;

export const PersonalForm = ({
  isPrecisionLookup = false,
  isPVerifyLookup = false,
  onSubmit: externalOnSubmit,
}: {
  isPrecisionLookup?: boolean;
  isPVerifyLookup?: boolean;
  onSubmit?: (data: PersonalFormData) => void;
}) => {
  const { setMultiStepFormData, multiStepFormData, goToPreviousFormStep } =
    useMultiStepFormStore();

  const navigate = useNavigate();

  const isPrecision = location.pathname === ROUTES.precisionForm;

  const formSchema = getPersonalFormSchema(isPrecision, isPrecisionLookup);

  type FormInputType = z.infer<typeof formSchema>;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...multiStepFormData?.personalFormData,
    },
    mode: "onSubmit",
  });

  const {
    mutate: getPrecisionAQMutation,
    isPending: isPendingPrecision,
    isError: isErrorPrecision,
  } = useGetPrecisionAQCoverage();

  const {
    mutate: getBPGMutation,
    isPending: isPendingBPG,
    isError: isErrorBPG,
  } = useGetBPGEligibility();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoadingNavigation = async (route: string) => {
    setIsLoading(true);
    await delay(DELAY_TIME);
    setIsLoading(false);
    navigate(route);
  };

  const handleInsuranceNavigation = (data: FormInputType) => {
    const insuranceType = multiStepFormData?.insuranceFormData?.insuranceType;

    if (insuranceType === "medicare") {
      return handleLoadingNavigation(ROUTES.providersList);
    }

    if (insuranceType === "medicare-advantage") {
      const route =
        data.firstName.toLowerCase() === "robert"
          ? ROUTES.pharmacy
          : ROUTES.providersList;
      return handleLoadingNavigation(route);
    }
  };

  const handleMutation = (data: FormInputType) => {
    if (isPrecision) {
      // getPrecisionAQMutation(data);
      getPrecisionAQMutation({
        ...data,
        insuranceType: multiStepFormData?.insuranceFormData?.insuranceType,
      });
    } else {
      // getBPGMutation(data);
      getBPGMutation({
        ...data,
        insuranceType: multiStepFormData?.insuranceFormData?.insuranceType,
      });
    }
  };

  const onSubmit: SubmitHandler<FormInputType> = async (data) => {
    setMultiStepFormData({
      personalFormData: { ...data },
    });

    if (externalOnSubmit) {
      externalOnSubmit(data);
      return;
    }

    const insuranceType = multiStepFormData?.insuranceFormData?.insuranceType;

    if (
      insuranceType === "medicare" ||
      insuranceType === "medicare-advantage"
    ) {
      return handleInsuranceNavigation(data);
    }

    handleMutation(data);
  };

  const isPending = isPendingPrecision || isPendingBPG || isLoading;

  const isError = isErrorPrecision || isErrorBPG;

  if (isPending) return <LoadingScreen />;

  return (
    <FormLayout
      isPrecisionLookup={isPrecisionLookup}
      isPVerifyLookup={isPVerifyLookup}
    >
      <div className="flex h-full w-full flex-col gap-4">
        {!(isPrecisionLookup || isPVerifyLookup) && (
          <div className="flex justify-between">
            <p className="text-lg font-medium">
              Fill in these details so we can check your eligibility.
            </p>
          </div>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full w-full flex-col justify-between"
        >
          <div className="grid w-full grid-cols-3 gap-9">
            <Input
              id="firstName"
              label="First name"
              {...register("firstName")}
              errorMessage={errors.firstName?.message}
            />

            <Input
              id="lastName"
              label="Last name"
              {...register("lastName")}
              errorMessage={errors.lastName?.message}
            />

            <DatePicker
              max={new Date().toISOString().split("T")[0]}
              id="dueDate"
              label={"Date of birth"}
              {...register("birthDate")}
            />

            <Controller
              control={control}
              name="state"
              render={({ field }) => (
                <SelectGroup className="w-full">
                  <SelectLabel className="mb-1 font-semibold text-[#07284A]">
                    State
                  </SelectLabel>
                  <Select {...field} onValueChange={field.onChange}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select state" />
                    </SelectTrigger>
                    <SelectContent>
                      {usaStatesOptions.map(({ label, value }) => {
                        return (
                          <SelectItem key={`${value}`} value={value}>
                            {label}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </SelectGroup>
              )}
            />

            {(isPrecision || isPrecisionLookup) && (
              <Input
                id="zipCode"
                label="Zip code"
                className="w-full"
                {...register("zipCode")}
                errorMessage={errors.zipCode?.message}
              />
            )}

            <div className="flex w-full flex-col gap-4">
              <p className="px-0 py-2 font-semibold text-[#07284A]">Gender</p>
              <div className="flex flex-row gap-4">
                <RadioButton
                  label={"Female"}
                  value={"Female"}
                  {...register("gender")}
                />
                <RadioButton
                  label={"Male"}
                  value={"Male"}
                  {...register("gender")}
                />
              </div>
            </div>
          </div>
          {!(isPrecisionLookup || isPVerifyLookup) ? (
            <div className="flex flex-col gap-6">
              {isError && <ErrorMessage />}
              <div className="flex justify-between">
                <Button
                  className={tw("w-1/4")}
                  variant="secondary"
                  onClick={() => goToPreviousFormStep()}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  disabled={!isValid}
                  isLoading={isPending}
                  className={tw("w-1/4")}
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          ) : (
            <div className="justify mt-6 flex">
              <Button
                variant="primary"
                disabled={!isValid}
                isLoading={isPending}
                className={tw("w-1/4")}
                type="submit"
              >
                Test
              </Button>
            </div>
          )}
        </form>
      </div>
    </FormLayout>
  );
};
