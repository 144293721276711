import type { ReactNode } from "react";

type ContentType = {
  title: React.ReactNode;
  description: string;
};

export const FormLayout = ({
  children,
  isPrecisionLookup = false,
  isPVerifyLookup = false,
  isBPG = false,
}: {
  children: ReactNode;
  isPrecisionLookup?: boolean;
  isPVerifyLookup?: boolean;
  isBPG?: boolean;
}) => {
  const content = {
    default: {
      title: (
        <>
          Take the First Step Toward{" "}
          <span className="font-medium">Smarter</span> Glucose Monitoring
        </>
      ),
      description:
        "Complete the form to help us determine if the Freestyle Libre CGM System is suitable for you or your loved one.",
    },
    pVerify: {
      title: "Test API 1",
      description:
        "Complete the form to see what information the API 1 returns.",
    },
    precision: {
      title: "Test API 2",
      description:
        "Complete the form to see what information the API 2 returns.",
    },
    bpg: {
      title: "Test API 3",
      description:
        "Complete the form to see what information the API 3 returns.",
    },
  };

  const getActiveContent = (): ContentType => {
    if (isPrecisionLookup) return content.precision;
    if (isPVerifyLookup) return content.pVerify;
    if (isBPG) return content.bpg;
    return content.default;
  };

  const activeContent = getActiveContent();

  return (
    <div className="mx-auto flex flex-col gap-12">
      <div className="flex flex-col gap-5 text-center">
        <h1 className="text-3xl text-[#07284A]">{activeContent.title}</h1>
        <p className="text-[#07284A]">{activeContent.description}</p>
      </div>
      {children}
    </div>
  );
};
