import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { twMerge as tw } from "tailwind-merge";
import { z } from "zod";

import { Input } from "../../components/Input";

import "react-international-phone/style.css";

import { Button } from "~/components/Button";
import ErrorMessage from "~/components/ErrorMessage";
import { FormLayout } from "~/components/forms";
import { ROUTES } from "~/router";
import { useGetBPGAllData } from "../../hooks/useGetBPGCoverage";
import { LoadingScreen } from "../LoadingScreen";

const formSchema = z.object({
  bin: z.string().min(1, { message: "BIN is required" }),
  pcn: z.string().optional(),
  group: z.string().optional(),
});

export type FormInputType = z.infer<typeof formSchema>;

export const BPGApiInformation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputType>({
    resolver: zodResolver(formSchema),
    mode: "onSubmit",
  });

  const {
    mutate: getBPGAllDataMutation,
    isPending,
    isError,
    data: response,
  } = useGetBPGAllData();

  const onSubmit: SubmitHandler<FormInputType> = (data) => {
    getBPGAllDataMutation(data);
  };

  if (isPending) return <LoadingScreen />;

  return (
    <>
      <FormLayout isBPG={true}>
        <div className="flex h-full flex-col gap-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex h-full flex-col justify-between"
          >
            <div className="flex flex-col gap-9">
              <div className="flex justify-between gap-4">
                <Input
                  id="bin"
                  label="BIN"
                  {...register("bin")}
                  errorMessage={errors.bin?.message}
                />

                <Input
                  id="pcn"
                  label="PCN"
                  {...register("pcn")}
                  errorMessage={errors.pcn?.message}
                />

                <Input
                  id="group"
                  label="Group"
                  {...register("group")}
                  errorMessage={errors.group?.message}
                />
              </div>
              <p className="text-center text-gray-600">
                You can find these details at the bottom of your insurance card
              </p>
            </div>
            <div className="flex flex-col gap-6">
              {isError && <ErrorMessage />}
              <div className="flex justify-between">
                <Button
                  className={tw("w-1/4")}
                  variant="secondary"
                  onClick={() => ROUTES.home}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  disabled={!isValid}
                  isLoading={isPending}
                  className={tw("w-1/4")}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </FormLayout>
      {response && response.results.length > 0 && (
        <div className="mx-auto mt-8 w-fit rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-6 text-2xl font-bold text-[#07284A]">
            Coverage Information
          </h2>

          <div className="grid grid-cols-2 gap-6 space-y-2">
            <div>
              <h3 className="mb-2 font-semibold text-[#07284A]">
                Patient Information
              </h3>
              <p>
                <span className="font-medium">Client ID:</span>{" "}
                {response.query.clientId}
              </p>
              <p>
                <span className="font-medium">BIN:</span>{" "}
                {response.results[0].BIN}
              </p>
              <p>
                <span className="font-medium">PCN:</span>{" "}
                {response.results[0].PCN}
              </p>
              <p>
                <span className="font-medium">Group:</span>{" "}
                {response.results[0].GROUP_ID}
              </p>
            </div>
            <div className="col-start-2">
              <h3 className="mb-2 font-semibold text-[#07284A]">
                Insurance Information
              </h3>
              <p>
                <span className="font-medium">Payer Entity:</span>{" "}
                {response.results[0].PAYER_ENTITY}
              </p>
              <p>
                <span className="font-medium">BOB:</span>{" "}
                {response.results[0].BOB}
              </p>
              <p>
                <span className="font-medium">Enterprise:</span>{" "}
                {response.results[0].ENTERPRISE}
              </p>
              <p>
                <span className="font-medium">Population Description:</span>{" "}
                {response.results[0].POP_DESC}
              </p>
              <p>
                <span className="font-medium">Payer Plan Detail:</span>{" "}
                {response.results[0].PAYER_PLAN_DETAIL}
              </p>
              <p>
                <span className="font-medium">PBM Processor Vendor:</span>{" "}
                {response.results[0].PBM_PROCESSOR_VENDOR}
              </p>
              <p>
                <span className="font-medium">Query ID:</span>{" "}
                {response.results[0].QUERY_ID}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
